import { Routes, Route } from 'react-router-dom'
import './scss/main.scss';
import 'bootstrap';
import Header from './components/Header';
import Home from './pages/Home';
import Aeronaves from './pages/Aeronaves';
import Motores from './pages/Motores';
import CapacitacionTecnica from './pages/CapacitacionTecnica';
import Componentes from './pages/Componentes';
import AeronavegabilidadContinuada from './pages/AeronavegabilidadContinuada';


function App() {

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="servicios/aeronaves" element={<Aeronaves />}></Route>
        <Route path="servicios/motores" element={<Motores />}></Route>
        <Route path="servicios/capacitacion-tecnica" element={<CapacitacionTecnica />}></Route>
        <Route path="servicios/componentes" element={<Componentes />}></Route>
        <Route path="servicios/aeronavegabilidad-continuada" element={<AeronavegabilidadContinuada />}></Route>
      </Routes>
    </>
  );
}

export default App;
