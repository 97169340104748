import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import Footer from '../components/Footer';
import FooterNavBar from '../components/FooterNavBar'


const Aeronaves = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <section className="services__above-the-fold"><img src={require("../assets/images/aeronavesHead.png")} alt="Aeronaves Aerolineas Argentinas"/></section>

      <section className="services__title">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <p>{t('sections.section2')}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="services__nav--first d-flex d-lg-none">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center"><Link to="/servicios/aeronavegabilidad-continuada" target="_self" rel="noopener noreferrer" title="Aeronavegabilidad Continuada">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                  <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" fill="rgba(0,33,87,1)"></path>
                </svg></Link>
              <h1>{t('services.service1.title')}</h1><Link to="/servicios/motores" target="_self" rel="noopener noreferrer" title="Motores">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" fill="rgba(0,33,87,1)"></path>
                </svg></Link>
            </div>
          </div>
        </div>
      </section>

      <section className="container-lg pt-4">
        <div className="row">
          <div className="col-12">
            <div>
              <h2 className="d-none d-lg-inline-flex">{t('services.service1.title')}</h2>
              <p className="mb-3"><Trans i18nKey="services.service1.description"><b></b><b></b><b></b><b></b></Trans></p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-lg">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h3>{t('services.service1.section1.title')}</h3>
            <ul className="mb-2">
              <Trans i18nKey="services.service1.section1.description">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </Trans>
            </ul>
            <h3>{t('services.service1.section2.title')}</h3>
            <ul className="mb-3">
              <Trans i18nKey="services.service1.section2.description">
                <li></li>
                <li></li>
              </Trans>
            </ul>
          </div>
          <div className="col-12 col-lg-6 services__img"><img src={require("../assets/images/aeronavesBody.jpg")} alt="Aeronaves Aerolineas Argentinas"/></div>
        </div>
      </section>

      <FooterNavBar active="aeronaves" />

      <Footer />
    </>
  )
}

export default Aeronaves;