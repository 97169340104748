import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer';
import FooterNavBar from '../components/FooterNavBar'

const Motores = () => {
  const { t } = useTranslation();

  return (
    <>
      <section class="services__above-the-fold"><img src={require("../assets/images/motoresHead.jpg")} alt="Motores Aerolineas Argentinas"/></section>
      
      <section class="services__title">
        <div class="container-lg">
          <div class="row">
            <div class="col-12">
              <p>{t('sections.section2')}</p>
            </div>
          </div>
        </div>
      </section>

      <section class="services__nav--first d-flex d-lg-none">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 d-flex justify-content-between align-items-center"><Link to="/servicios/aeronaves" target="_self" rel="noopener noreferrer" title="Aeronaves">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                  <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" fill="rgba(0,33,87,1)"></path>
                </svg></Link>
              <h1>{t('services.service2.title')}</h1><Link to="/servicios/capacitacion-tecnica" target="_self" rel="noopener noreferrer" title="Capacitación Técnica">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" fill="rgba(0,33,87,1)"></path>
                </svg></Link>
            </div>
          </div>
        </div>
      </section>

      <section class="container-lg pt-4">
        <div class="row">
          <div class="col-12">
            <div>
              <h2 class="d-none d-lg-inline-flex">{t('services.service2.title')}</h2>
              <p class="mb-3"><Trans i18nKey="services.service2.description"><b></b><b></b></Trans></p>
            </div>
          </div>
        </div>
      </section>

      <section class="container-lg">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h3>{t('services.service2.section1.title')}</h3>
            <ul class="services__list">
              <Trans i18nKey="services.service2.section1.description">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </Trans>
            </ul>
            <h3>{t('services.service2.section2.title')}</h3>
            <ul class="services__list mb-3">
              <Trans i18nKey="services.service2.section2.description">
                <li></li>
              </Trans>
            </ul>
            <h3>{t('services.service2.section3.title')}</h3>
            <h4>{t('services.service2.section3.subtitle1')}</h4>
            <ul class="services__list mb-3">
              <Trans i18nKey="services.service2.section3.description1">
                <li></li>
                <li></li>
                <li></li>
              </Trans>
            </ul>
            <h4>{t('services.service2.section3.subtitle2')}</h4>
            <ul class="services__list mb-3">
              <Trans i18nKey="services.service2.section3.description2">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </Trans>
            </ul>
            <h4>{t('services.service2.section3.subtitle3')}</h4>
            <ul class="services__list mb-3">
              <Trans i18nKey="services.service2.section3.description3">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </Trans>
            </ul>
            <h4>{t('services.service2.section3.subtitle4')}</h4>
            <ul class="services__list mb-3">
              <Trans i18nKey="services.service2.section3.description4">
                <li></li>
                <li></li>
                <li></li>
              </Trans>
            </ul>
            <h3>{t('services.service2.section4.title')}</h3>
            <ul class="services__list mb-3">
              <Trans i18nKey="services.service2.section4.description">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </Trans>
            </ul>
          </div>
          <div class="col-12 col-lg-6 services__img"><img src={require("../assets/images/motoresBody.jpg")} alt="Motores Aerolineas Argentinas"/></div>
        </div>
      </section>

      <FooterNavBar active="motores" />
      
      <Footer />
    </>
  )
}

export default Motores;