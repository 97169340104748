import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Content() {
  const { t } = useTranslation();

  return (
    <>
      <section className="section" data-anchor="section0">
        <div className="slide active" id="slide1" data-anchor="slide1"><img className="d-flex d-lg-none mb-2" src={require('../assets/images/homeHead1Mobile.jpg')} alt={t('Content.slide1.title')}/>
          <div className="container-lg">
            <div className="row">
              <div className="col-12 offset-lg-7 col-lg-4 offset-xl-8">
                <h1>{t('Content.slide1.title')}</h1>
                <hr/>
                <p className="mb-0">{t('Content.slide1.description')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="slide" id="slide2" data-anchor="slide2"><img className="d-flex d-lg-none mb-2" src={require('../assets/images/homeHead2Mobile.jpg')} alt={t('Content.slide2.title')}/>
          <div className="container-lg">
            <div className="row">
              <div className="col-12 offset-lg-7 col-lg-4 offset-xl-8"><img className="d-flex ms-0 ms-lg-auto" src={require('../assets/images/logo_MRO.png')} alt={t('Content.slide2.title')} height="80"/>
                <hr/>
                <p className="mb-0">{t('Content.slide2.description')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" data-anchor="section1">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <h2 className="d-inline-flex">{t('sections.section1')}</h2>
              <p className="mb-4"><Trans i18nKey="Content.section1.description">text <b>text</b>, text <b>text</b>, text.</Trans></p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-4 text-center"><img className="img-fluid me-auto ms-auto" src={require('../assets/images/homeEscalasInternacionales.png')} alt="Escalas internacionales"/>
              <p className="font-size-lg mb-0"><b>{t('Content.section1.internationalStations.amount')}</b></p>
              <p className="text-primary mb-2 mb-lg-0">{t('Content.section1.internationalStations.text')}</p>
            </div>
            <div className="col-12 col-sm-4 text-center"><img className="img-fluid me-auto ms-auto" src={require('../assets/images/homeHangares.png')} alt="Hangares"/>
              <p className="font-size-lg mb-0"><b>{t('Content.section1.hangars.amount')}</b></p>
              <p className="text-primary mb-2 mb-lg-0">{t('Content.section1.hangars.text')}</p>
            </div>
            <div className="col-12 col-sm-4 text-center"><img className="img-fluid me-auto ms-auto" src={require('../assets/images/homeProfesionales.png')} alt="Profesionales"/>
              <p className="font-size-lg mb-0"><b>{t('Content.section1.professionals.amount')}</b></p>
              <p className="text-primary mb-0">{t('Content.section1.professionals.text')}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section" data-anchor="section2">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <h2 className="d-inline-flex">{t('sections.section2')}</h2>
            </div>
          </div>
          <div className="row home__services">
            <div className="col-11 col-sm-8 col-lg-4 mb-0 mb-lg-2"><Link className="home__services__link" to="servicios/aeronaves"> <img src={require('../assets/images/homeAeronaves.png')} alt={t('services.service1.title')}/>
                <h4>{t('services.service1.title')}</h4></Link></div>
            <div className="col-11 col-sm-8 col-lg-4 mb-0 mb-lg-2"><Link className="home__services__link" to="servicios/motores"> <img src={require('../assets/images/homeMotores.png')} alt={t('services.service2.title')}/>
                <h4>{t('services.service2.title')}</h4></Link></div>
            <div className="col-11 col-sm-8 col-lg-4 mb-0 mb-lg-2"><Link className="home__services__link" to="servicios/capacitacion-tecnica"><img src={require('../assets/images/homeCapacitacionTecnica.png')} alt={t('services.service3.title')}/>
                <h4>{t('services.service3.title')}</h4></Link></div>
            <div className="col-11 col-sm-8 col-lg-4"><Link className="home__services__link" to="servicios/componentes"> <img src={require('../assets/images/homeComponentes.png')} alt={t('services.service4.title')}/>
                <h4>{t('services.service4.title')}</h4></Link></div>
            <div className="col-11 col-sm-8 col-lg-4"><Link className="home__services__link" to="servicios/aeronavegabilidad-continuada"><img src={require('../assets/images/homeAeronavegabilidadContinuada.png')} alt={t('services.service5.title')}/>
                <h4>{t('services.service5.title')}</h4></Link></div>
          </div>
        </div>
      </section>
      <section className="section" data-anchor="section3">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <h2 className="d-inline-flex">{t('sections.section3')}</h2>
            </div>
            <div className="col-12 col-sm-4 home__certifications mb-3 mb-lg-0">
              <h3>FAA</h3><img src={require('../assets/images/homeFAA-baja.png')} alt="FAA"/>
              <hr/>
              <p>FAR Part 145 Foreign Certificated Repair Station (AISY117C)</p>
            </div>
            <div className="col-12 col-sm-4 home__certifications mb-3 mb-lg-0">
              <h3>ANAC</h3><img src={require('../assets/images/homeANAC-baja.png')} alt="ANAC"/>
              <hr/>
              <p>RAAC Part 145 Certificated Aeronautical Workshop (1B-114)</p>
            </div>
            <div className="col-12 col-sm-4 home__certifications">
              <h3>DIGAMC</h3><img src={require('../assets/images/homeDIGAMC-baja.png')} alt="DIGAMC"/>
              <hr/>
              <p>Recognition Certificate for Defense Maintenance Organization under Military Regulation</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Content;