import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import FooterNavBar from '../components/FooterNavBar'

const Componentes = () => {
  const { t } = useTranslation();

  return (
    <>
      <section class="services__above-the-fold"><img src={require("../assets/images/componentesHead.png")} alt="Componentes Aerolineas Argentinas"/></section>

      <section class="services__title">
        <div class="container-lg">
          <div class="row">
            <div class="col-12">
              <p>{t('sections.section2')}</p>
            </div>
          </div>
        </div>
      </section>

      <section class="services__nav--first d-flex d-lg-none">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12"><Link to="/servicios/capacitacion-tecnica" target="_self" rel="noopener noreferrer" title="Capacitación Técnica">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                  <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" fill="rgba(0,33,87,1)"></path>
                </svg></Link>
              <h1>{t('services.service4.title')}</h1><Link to="/servicios/aeronavegabilidad-continuada" target="_self" rel="noopener noreferrer" title="Aeronavegabilidad Continuada">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" fill="rgba(0,33,87,1)"></path>
                </svg></Link>
            </div>
          </div>
        </div>
      </section>

      <section class="container-lg pt-4">
        <div class="row">
          <div class="col-12">
            <div>
              <h2 class="d-none d-lg-inline-flex">{t('services.service4.title')}</h2>
              <p class="mb-3"><Trans i18nKey="services.service4.description"><b></b><b></b></Trans></p>
            </div>
          </div>
        </div>
      </section>

      <section class="container-lg">
        <div class="row">
          <div class="col-12 col-lg-3">
            <h3>{t('services.service4.section1.title')}</h3>
            <ul class="services__list">
              <Trans i18nKey="services.service4.section1.description">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </Trans>
            </ul>
          </div>
          <div class="col-12 col-lg-3 services__img d-flex flex-row flex-lg-column mb-2"><img class="me-1 me-lg-0 mb-0 mb-lg-2" src={require("../assets/images/componentesBody1.jpg")} alt="Componentes Aerolineas Argentinas"/><img class="ms-1 ms-lg-0" src={require("../assets/images/componentesBody2.jpg")} alt="Componentes Aerolineas Argentinas"/></div>
          <div class="col-12 col-lg-6 services__img"><img src={require("../assets/images/componentesBody3.jpg")} alt="Componentes Aerolineas Argentinas"/></div>
        </div>
      </section>

      <FooterNavBar active="componentes" />

      <Footer />
    </>
  )
}

export default Componentes;