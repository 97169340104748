import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

const FooterNavBar = ({active}) => {

  const { t } = useTranslation('translation', { keyPrefix: 'services'});

  function isActive(section) {
    return active == section ? 'active' : ''
  }

  return (
  <>
    <section className="services__nav--last d-none d-lg-flex">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="nav">
              <Link className={`nav-link ${isActive('aeronaves')}`} to="/servicios/aeronaves" target="_self" rel="noopener noreferrer" title={t('service1.title')}>{t('service1.title')}</Link>
              <Link className={`nav-link ${isActive('motores')}`} to="/servicios/motores" target="_self" rel="noopener noreferrer" title={t('service2.title')}>{t('service2.title')}</Link>
              <Link className={`nav-link ${isActive('capacitacion-tecnica')}`} to="/servicios/capacitacion-tecnica" target="_self" rel="noopener noreferrer" title={t('service3.title')}>{t('service3.title')}</Link>
              <Link className={`nav-link ${isActive('componentes')}`} to="/servicios/componentes" target="_self" rel="noopener noreferrer" title={t('service4.title')}>{t('service4.title')}</Link>
              <Link className={`nav-link ${isActive('aeronavegabilidad-continuada')}`} to="/servicios/aeronavegabilidad-continuada" target="_self" rel="noopener noreferrer" title={t('service5.title')}>{t('service5.title')}</Link>
            </nav>
          </div>
        </div>
      </div>
    </section>
  </>
);}

export default FooterNavBar;