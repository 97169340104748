import { useTranslation } from 'react-i18next';

function Header() {
  const { t, i18n } = useTranslation('translation');

  function isActive(language) {
    return t('Header.language.selected') == language ? 'active' : ''
  }

  let onClickHandler = lng => (e) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  }

  return (
    <header className="sticky-top">
      <nav className="navbar">
        <div className="container-lg">
          <a className="navbar-brand" href="/#section0">
            <img className="img-fluid" src={require('../assets/images/AR_Tecnica-color.png')} alt={t('Header.title')} style={{width: 'auto', height: 40}}/>
          </a>
          <div className="d-inline-flex">
            <div className="dropdown me-11"><a className="text-primary d-flex align-items-center dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                  <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z" fill="rgba(0,33,87,1)"></path>
                </svg><span className="d-none d-sm-flex">{t('Header.language.selected')}</span></a>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
                <li><a className={`dropdown-item ${isActive(t('Header.language.es'))}`} href="#" onClick={onClickHandler('es')}>{t('Header.language.es')}</a><a className={`dropdown-item ${isActive(t('Header.language.en'))}`} href="#" onClick={onClickHandler('en')}>{t('Header.language.en')}</a></li>
              </ul>
            </div>
            <button className="d-flex d-lg-none navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" fill="rgba(0,33,87,1)"></path>
              </svg>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav" id="menu">
              <li className="nav-item" data-menuanchor="section1"><a className="nav-link" href="/#section1" target="_self" rel="noopener noreferrer" title={t('sections.section1')}>{t('sections.section1')}</a></li>
              <li className="nav-item" data-menuanchor="section2"><a className="nav-link" href="/#section2" target="_self" rel="noopener noreferrer" title={t('sections.section2')}>{t('sections.section2')}</a></li>
              <li className="nav-item" data-menuanchor="section3"><a className="nav-link" href="/#section3" target="_self" rel="noopener noreferrer" title={t('sections.section3')}>{t('sections.section3')}</a></li>
              <li className="nav-item" data-menuanchor="section4"><a className="nav-link" href="/#section4" target="_self" rel="noopener noreferrer" title={t('sections.section4')}>{t('sections.section4')}</a></li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="header__nav">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ul className="nav" id="menu">
                <li className="nav-link" data-menuanchor="section1"><a href="/#section1" target="_self" rel="noopener noreferrer" title={t('sections.section1')}>{t('sections.section1')}</a></li>
                <li className="nav-link" data-menuanchor="section2"><a href="/#section2" target="_self" rel="noopener noreferrer" title={t('sections.section2')}>{t('sections.section2')}</a></li>
                <li className="nav-link" data-menuanchor="section3"><a href="/#section3" target="_self" rel="noopener noreferrer" title={t('sections.section3')}>{t('sections.section3')}</a></li>
                <li className="nav-link" data-menuanchor="section4"><a href="/#section4" target="_self" rel="noopener noreferrer" title={t('sections.section4')}>{t('sections.section4')}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
