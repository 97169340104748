import ReactFullpage from '@fullpage/react-fullpage';
import Content from './Content';
import Footer from './Footer';

const HomeReactFullPage = () => {
  return (
    <ReactFullpage
      menu = '#menu'
      anchors = {['section0', 'section1', 'section2', 'section3', 'section4']}
      navigation = {true}
      navigationPosition = 'right'
      navigationTooltips = {['Inicio', '¿Quienes somos?', 'Servicios', 'Certificaciones', 'Contacto']}
      showActiveTooltip = {false}
      slidesNavigation = {true}
      slidesNavPosition = 'bottom'
      css3 = {true}
      scrollingSpeed = {1000}
      dragAndMove = {true}
      resetSliders = {false}
      fadingEffect = {true}
      scrollOverflow = {true}
      controlArrows = {true}
      controlArrowsHTML= {[
          '<svg xmlns="http://www.w3.org/2000/svg", viewBox="0 0 24 24", width="48", height="48"><path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" fill="rgba(255,255,255,1)"></svg>', 
          '<svg xmlns="http://www.w3.org/2000/svg", viewBox="0 0 24 24", width="48", height="48"><path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" fill="rgba(255,255,255,1)"></svg>'
      ]}
      controlArrowColor = '#0075C9'
      verticalCentered = {true}
      sectionsColor = {['#0075C9', '#FFFFFF', '#FFFFFF', '#FFFFFF','#0075C9',]}
      paddingTop = {138}
      responsiveWidth = {991}
      parallax = {false}
      dropEffect = {false}
      waterEffect = {false}
      cards = {false}
      lazyLoading = {true}
      credits = {{ enabled: false}}
      licenseKey = ''
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <Content />
            <Footer />
          </ReactFullpage.Wrapper>
        );
      }}
    />
)}

export default HomeReactFullPage;