import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="section" data-anchor="section4">
      <div className="container-lg py-4">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center"><a href="https://www.instagram.com/" target="_blank" title="Ir a Instagram">
              <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1091_4211)">
                  <path d="M11.334 1.33325H4.667C2.82596 1.33325 1.3335 2.82571 1.3335 4.66676V11.3338C1.3335 13.1748 2.82596 14.6673 4.667 14.6673H11.334C13.1751 14.6673 14.6675 13.1748 14.6675 11.3338V4.66676C14.6675 2.82571 13.1751 1.33325 11.334 1.33325Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M10.6671 7.58032C10.7493 8.13518 10.6546 8.70185 10.3962 9.19974C10.1379 9.69764 9.72912 10.1014 9.22808 10.3536C8.72704 10.6058 8.15924 10.6935 7.60544 10.6044C7.05163 10.5153 6.54003 10.2538 6.1434 9.85721C5.74676 9.46057 5.48529 8.94897 5.39618 8.39517C5.30706 7.84137 5.39484 7.27356 5.64703 6.77253C5.89922 6.27149 6.30297 5.86272 6.80086 5.60438C7.29875 5.34603 7.86543 5.25126 8.42029 5.33354C8.98626 5.41746 9.51024 5.6812 9.91483 6.08578C10.3194 6.49036 10.5831 7.01434 10.6671 7.58032Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
                  <path d="M11.6672 4.33337H11.6739" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
                <defs>
                  <clipPath id="clip0_1091_4211">
                    <rect width="32" height="32" fill="white" transform="translate(0 -0.00012207)"></rect>
                  </clipPath>
                </defs>
              </svg></a><a className="mx-2" href="https://www.youtube.com/" target="_blank" title="Ir a Youtube">
              <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0291 4.28004C14.9499 3.96363 14.7886 3.67373 14.5615 3.4396C14.3344 3.20548 14.0495 3.03543 13.7357 2.94664C12.589 2.66663 8.00206 2.66663 8.00206 2.66663C8.00206 2.66663 3.41516 2.66663 2.26843 2.97331C1.95458 3.0621 1.66972 3.23215 1.44262 3.46627C1.21552 3.70039 1.05423 3.9903 0.975032 4.30671C0.765165 5.47047 0.662507 6.65104 0.66835 7.83356C0.660869 9.02498 0.763533 10.2145 0.975032 11.3871C1.06234 11.6937 1.22725 11.9725 1.45382 12.1968C1.68039 12.421 1.96096 12.583 2.26843 12.6671C3.41516 12.9738 8.00206 12.9738 8.00206 12.9738C8.00206 12.9738 12.589 12.9738 13.7357 12.6671C14.0495 12.5783 14.3344 12.4083 14.5615 12.1742C14.7886 11.9401 14.9499 11.6501 15.0291 11.3337C15.2373 10.1787 15.34 9.00717 15.3358 7.83356C15.3432 6.64213 15.2406 5.45257 15.0291 4.28004V4.28004Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M6.50171 10.0137L10.3352 7.83355L6.50171 5.65344V10.0137Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
              </svg></a><a href="https://www.facebook.com/" target="_blank" title="Ir a Facebook">
              <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1091_4207)">
                  <path d="M12.0019 1.33325H10.0018C9.11772 1.33325 8.26983 1.68446 7.64468 2.30961C7.01952 2.93477 6.66832 3.78266 6.66832 4.66676V6.66686H4.66821V9.33366H6.66832V14.6673H9.33512V9.33366H11.3352L12.0019 6.66686H9.33512V4.66676C9.33512 4.48994 9.40536 4.32036 9.53039 4.19533C9.65542 4.0703 9.825 4.00006 10.0018 4.00006H12.0019V1.33325Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
                <defs>
                  <clipPath id="clip0_1091_4207">
                    <rect width="32" height="32" fill="white" transform="translate(0 -0.00012207)"></rect>
                  </clipPath>
                </defs>
              </svg></a></div>
          <div className="col-12 d-flex flex-column justify-content-center align-items-center">
            <h3 className="mt-3 mb-1">{t('Footer.contact')}</h3><a href="mailto:tecnica@aerolineas.com.ar" target="_blank" rel="noopener noreferrer" title="Enviar correo electrónico">{t('Footer.mail')}</a>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ul className="nav py-3 my-4">
              <li className="nav-item"><a className="nav-link" href="/#section1" target="_self" rel="noopener noreferrer" title={t('sections.section1')}>{t('sections.section1').toUpperCase()}</a></li>
              <li className="nav-item mx-0 mx-lg-4 my-2 my-lg-0"><a className="nav-link" href="/#section2" target="_self" rel="noopener noreferrer" title={t('sections.section2')}>{t('sections.section2').toUpperCase()}</a></li>
              <li className="nav-item"><a className="nav-link" href="/#section3" target="_self" rel="noopener noreferrer" title={t('sections.section3')}>{t('sections.section3').toUpperCase()}</a></li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center"><a href="/#section0"><img className="img-fluid" src={require('../assets/images/AR_Tecnica-white.png')} alt="Aerolineas Argentinas Técnica" style={{width: 'auto', height: 40}}/></a></div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;